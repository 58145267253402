<template>
    <main class="tx-code-json">
        <ui-copy-button
            class="source-viewer-code__copy tx-code-json-copy"
            v-bind:copy="formattedDescription"
        />
        <div
            class="tx-code tx-page-msg"
            v-bind:class="{'tx-code-json__hide': hiddenJson && hiddenActive}"
        >{{ formattedDescription }}</div>
        <div v-show="hiddenJson && hiddenActive" class="tx-code-expander" v-on:click="toggleContent">
            Show more
        </div>
    </main>
</template>

<script>

export default {
    props: {
        transactionDescription: Object,
        hiddenJson: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            hiddenActive: false,
        };
    },

    computed: {
        description() {
            return this.transactionDescription || {};
        },
        /* eslint-disable vue/no-side-effects-in-computed-properties */
        formattedDescription() {
            const formatted = JSON.stringify(this.description, null, 4);

            console.log(formatted.length);

            if (formatted.length > 500) {
                this.hiddenActive = true;
            } else {
                this.hiddenActive = false;
            }

            return formatted;
        },
        /* eslint-enable vue/no-side-effects-in-computed-properties */
    },

    methods: {
        toggleContent() {
            this.hiddenActive = !this.hiddenActive;
        },
    },
};
</script>

<style lang="scss">
.tx-code-json {
    position: relative;

    &__hide {
        height: 95px;
        overflow: hidden;
    }

    &__hide::after {
        content: "";
        position: absolute;
        bottom: 1px;
        left: 1px;
        right: 1px;
        height: 120px;
        background: linear-gradient(to top, var(--hidden-json-expander) 20%, transparent);
        pointer-events: none;
        border-radius: 0 0 8px 8px;
    }
}

.tx-code {
    padding: 10px;
    display: block;
    white-space: pre-wrap;
    word-break: break-all;
}

.tx-code-json-copy {
    background: var(--tx-messages-message-background);
}

.tx-code-expander {
    position: absolute;
    bottom: 20px;
    left: 50%;
    font-weight: 500;
    cursor: pointer;
    z-index: 900;
    transform: translate(-50%, 0%);
}
</style>
