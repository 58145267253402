<template>
    <tr>
        <td class="tx-mobile-flex-content">
            <div class="tx-token-cell-icon">
                <img v-bind:src="wallet.jetton_meta.image.w72" />
            </div>
            <div class="tx-mobile-content">
                <div class="tx-mobile-content__top">
                    <div class="tx-mobile-content-row">
                        <span class="tx-mobile-content-jetton-name">{{ wallet.jetton_meta.name }}</span>
                        <span>
                            {{ $ton(wallet.balance, wallet.jetton_meta.decimals) }}
                            <span class="muted">  {{ wallet.jetton_meta.symbol }}</span>
                        </span>
                        <!-- <ui-address v-bind:address="wallet.address"/> -->
                    </div>
                    <div class="tx-mobile-content-row">
                        <span class="muted" style="margin-bottom: 10px;"><ui-format-price v-bind:price="getJettonPrice(wallet) || 0" v-bind:symbol="getSymbolStore()"/></span>
                        <span class="muted" style="margin-bottom: 10px;">
                            <ui-format-price v-bind:price="getJettonRate(wallet) || 0" v-bind:symbol="getSymbolStore()"/>
                        </span>
                    </div>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
import { formatTons } from '~/helpers.js';

export default {
    props: {
        wallet: Object,
        rates: Object,
    },

    methods: {
        getJettonRate(wallet) {
            const jettonsCount = parseFloat(formatTons(wallet.balance, wallet.jetton_meta.decimals).replace(/\s/g, '').replace(',', '.'));
            const currencyTicker = this.$store.state.exchangeRateCurrency.toUpperCase();

            const rate = this.rates[wallet.jetton_address]?.prices[currencyTicker];

            if (rate !== undefined) {
                return rate * jettonsCount;
            }

            return null;
        },

        getJettonPrice(wallet) {
            const currencyTicker = this.$store.state.exchangeRateCurrency.toUpperCase();
            const rate = this.rates[wallet.jetton_address]?.prices[currencyTicker];
            return rate;
        },

        getSymbolStore() {
            const symbolStore = this.$store.state.exchangeRateCurrency;

            switch (symbolStore.toLowerCase()) {
                case 'rub':
                    return '₽';
                case 'usd':
                    return '$';
                case 'aed':
                    return 'AED';
                default:
                    return symbolStore;
            }
        },
    },
};
</script>

<style>
.tx-token-cell-icon {
    margin-top: 1em !important;
    margin-left: 1em !important;
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    display: flex;
    align-items: center;
    align-self: flex-start;
    justify-content: center;
    border-radius: 32px !important;
    overflow: hidden;
}

.tx-token-cell-icon img {
    width: 100%;
    height: 100%;
}

.tx-mobile-content-jetton-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 20px;
}
</style>
